












































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import eventHub from "@/event-hub";
import debounce from "lodash/debounce";
import { VueEditor } from "vue2-editor";

import {
    SessionDataObject,
    SessionSpeakerObject,
    AttendeeSearchResult
} from "@/types/interfaces";

import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import wysiwyg from "@/components/utilities/wysiwyg.vue";

import sessionVuexModule from "@/store/vuex-modules/getSessionData";

import attendeeSearchVuexModule from "@/store/vuex-modules/searchAttendeeData";
const attendeeSearchStore = getModule(attendeeSearchVuexModule);

const sessionStore = getModule(sessionVuexModule);

interface SessionData {
    sessionId: "";
    title: string;
    description: string;
    speakers: Array<SessionSpeakerObject>;
    videoUrl: string;
    videoUrlOnDemand: string;
}

@Component({
    components: {
        PageTitleHeader,
        Spinners,
        wysiwyg,
        VueEditor
    }
})
export default class EditSession extends Vue {
    @Prop(String)
    id!: string;

    isLoading = true;
    isOpen = false;
    isLoadingAttendees = false;
    attendeeData: any[] = [];

    searchSelection: null | AttendeeSearchResult = null;

    updatePayload: SessionDataObject | SessionData = {
        sessionId: "",
        title: "",
        description: "",
        speakers: [],
        videoUrl: "",
        videoUrlOnDemand: ""
    };
    resetPayload: SessionDataObject | SessionData = {
        sessionId: "",
        title: "",
        description: "",
        speakers: [],
        videoUrl: "",
        videoUrlOnDemand: ""
    };

    unregisteredSpeaker: SessionSpeakerObject = {
        name: "",
        biography: "",
        title: "",
        id: ""
    };

    addToCategory = "";
    submitSuccess = false;
    submitError = false;

    get attendeeSearchResults() {
        return attendeeSearchStore.attendeeSearchResults;
    }

    @Watch("searchSelection")
    addSelectionTospeakerList() {
        const newSpeaker: any = {
            biography: "",
            id: this.searchSelection?.attendeeId,
            name: `${this.searchSelection?.firstName} ${this.searchSelection?.lastName}`,
            title: this.searchSelection?.title
        };

        this.updatePayload.speakers.push(newSpeaker);

        // this.updatePayload.speakers
    }
    created() {
        this.initializeSession();

        eventHub.$on("session-update-started", this.handleUpdateStarted);
        eventHub.$on("session-update-success", this.handleUpdateSuccess);
        eventHub.$on("session-update-error", this.handleUpdateError);
        eventHub.$on("session-update-done", this.handleUpdateDone);
    }

    beforeDestroy() {
        this.resetSubmitFlags();

        eventHub.$off("session-update-started", this.handleUpdateStarted);
        eventHub.$off("session-update-success", this.handleUpdateSuccess);
        eventHub.$off("session-update-error", this.handleUpdateError);
        eventHub.$off("session-update-done", this.handleUpdateDone);
    }

    initializeSession() {
        const SessionsPromises = [this.fetchSession()];

        this.isLoading = true;

        Promise.resolve(SessionsPromises);
    }
    setUpdatePayload() {
        const payload: any = this.updatePayload;
        sessionStore.setSessionPayload(payload);
    }
    handleSubmit() {
        this.setUpdatePayload();
        this.$emit("submit");
    }
    handleUpdateStarted() {
        this.isLoading = true;
    }
    handleUpdateSuccess() {
        this.submitSuccess = true;
    }
    handleUpdateError() {
        this.submitError = true;
    }
    handleUpdateDone() {
        this.isLoading = false;
    }

    fetchSession() {
        return new Promise<void>((resolve, reject) => {
            sessionStore
                .getSession(this.id)
                .then((response: any) => {
                    this.updatePayload.sessionId = response.sessionId;
                    this.updatePayload.title = response.title;
                    this.updatePayload.description = response.description;
                    this.updatePayload.speakers = response.speakers
                        ? [...response.speakers]
                        : [];
                    this.updatePayload.videoUrl = response.videoUrl;
                    this.updatePayload.videoUrlOnDemand =
                        response.videoUrlOnDemand;

                    this.isLoading = false;
                    return resolve();
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    handleCancel() {
        this.resetForm();
        this.$emit("cancel");
    }
    resetForm() {
        this.updatePayload = JSON.parse(JSON.stringify(this.resetPayload));
    }

    resetSubmitFlags() {
        this.isLoading = false;
        this.submitSuccess = false;
        this.submitError = false;
    }

    resetFlags() {
        this.isLoadingAttendees = false;
    }
    getAsyncData(keyword: string) {
        this.isLoadingAttendees = true;
        this.fetchAttendees(keyword);
    }

    fetchAttendees = debounce((keyword: string) => {
        const searchInput = {
            allFields: keyword
        };
        attendeeSearchStore
            .searchAttendees(searchInput)
            .then((res: any) => {
                this.attendeeData = [];
                res.forEach((item: any) => {
                    this.attendeeData.push(item);
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.resetFlags();
            });
    }, 500);

    removeSpeaker(speaker: string) {
        const updateSpeaker = this.updatePayload.speakers.filter(
            (item: SessionSpeakerObject) => {
                return item.name != speaker;
            }
        );
        this.updatePayload.speakers = [];
        this.updatePayload.speakers = [...updateSpeaker];
    }
    addUnregisteredSpeaker() {
        const newSpeaker: SessionSpeakerObject = {
            ...this.unregisteredSpeaker
        };
        this.updatePayload.speakers.push(newSpeaker);
        this.isOpen = false;
        this.clearUnregisteredSpeakerField();
    }
    clearUnregisteredSpeakerField() {
        this.unregisteredSpeaker.name = "";
        this.unregisteredSpeaker.id = "";
        this.unregisteredSpeaker.title = "";
        this.unregisteredSpeaker.biography = "";
        this.isOpen = false;
    }

    changeDescription(desc: any) {
        this.updatePayload.description = desc;
    }
}
