





































import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { SurveyModel } from "survey-vue";
import { VisualizationPanel } from "survey-analytics";

import sessionEvalVuexModule from "@/store/vuex-modules/admin-session-evaluations";
const evalStore = getModule(sessionEvalVuexModule);

interface FilterOption {
    name: string;
    count: number;
    id: string;
}

const defaultQuestions = [
    {
        type: "rating",
        name: "question1",
        title: "Please rate the quality of the CONTENT of the session:",
        hideNumber: true,
        isRequired: true
    },
    {
        type: "rating",
        name: "question2",
        title: "Please rate the quality of the PRESENTATION of the session:",
        hideNumber: true,
        isRequired: true
    },
    {
        type: "radiogroup",
        name: "question3",
        title:
            "Please rate the relevance of the session to you, your job and your company:",
        hideNumber: true,
        isRequired: true,
        choices: [
            "Extremely relevant",
            "Very relevant",
            "Somewhat relevant",
            "Little relevance",
            "Not relevant"
        ]
    },
    {
        type: "comment",
        name: "question4",
        hideNumber: true,
        title: "Any other comments?"
    }
];

@Component({
    components: {}
})
export default class AdminEvalsView extends Vue {
    activeSession = "";
    resultsPanel?: VisualizationPanel;

    get responses() {
        return evalStore.responses ? evalStore.responses : [];
    }

    get filteredResponses() {
        return this.activeSession
            ? this.responses.filter(
                  (resp) => resp.sessionId === this.activeSession
              )
            : this.responses;
    }

    get filterValues() {
        const retArr: Array<FilterOption> = [];

        retArr.push({
            name: "All",
            count: this.responses.length,
            id: ""
        });

        this.responses.forEach((resp) => {
            const current = retArr.find((item) => item.name === resp.sessionId);

            if (current) {
                current.count++;
            } else {
                retArr.push({
                    name: resp.sessionId,
                    id: resp.sessionId,
                    count: 1
                });
            }
        });

        return retArr;
    }

    get evalOptions() {
        return this.$store.getters.getPageOptions("sessionEvals");
    }

    get questions() {
        return this.evalOptions &&
            Array.isArray(this.evalOptions.questions) &&
            this.evalOptions.questions.length
            ? this.evalOptions.questions
            : defaultQuestions;
    }

    get evalsEnabled() {
        return this.evalOptions && "enabled" in this.evalOptions
            ? this.evalOptions.enabled
            : true;
    }

    @Watch("activeSession")
    reInitDisplayOnSessionChange() {
        this.refreshEvaluations();
    }

    async created() {
        this.refreshEvaluations();
    }

    async refreshEvaluations() {
        await this.updateEvaluations();
        await this.initializeResults();
    }

    async updateEvaluations() {
        await evalStore.getResponses();
    }

    async initializeResults() {
        this.resultsPanel = undefined;
        const pollObj = {
            pollId: "",
            pages: [
                {
                    name: "page1",
                    elements: this.questions
                }
            ]
        };

        const survey = new SurveyModel(pollObj);
        const questions = survey.getAllQuestions();
        const answers = this.filteredResponses.map((resp) => resp.answers);

        const analyticsOptions = {
            haveCommercialLicense: true,
            allowDynamicLayout: false,
            allowHideQuestions: false
        };

        const dataTables = new VisualizationPanel(
            questions,
            answers,
            analyticsOptions
        );

        // filteredQuestions.forEach((question: Question) => {
        //     const visualizer = dataTables.getVisualizer(question.name);
        //     const validTypes = ["bar", "pie", "doughnut", "scatter"];
        //     const chartType =
        //         poll.chartType && validTypes.includes(poll.chartType)
        //             ? poll.chartType
        //             : "pie";
        //     /**
        //      * TODO: Fix explicit any. For some reason the VisualizerBase type this variable is set as throws
        //      * a property does not exist error, despite the fact that this works fine.
        //      */
        //     (visualizer as any).setChartType(chartType);
        // });

        this.resultsPanel = dataTables;

        const container = document.getElementById("pollResultsPanel");

        if (container) {
            container.innerHTML = "";
            this.resultsPanel.render(container);
        }
    }
}
