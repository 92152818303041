

































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import eventHub from "@/event-hub";

import { SessionDataObject } from "@/types/interfaces";
import EditSession from "@/components/admin/sessions/EditSession.vue";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";

const sessionStore = getModule(sessionVuexModule);

@Component({
    components: {
        EditSession
    }
})
export default class AdminSessionScheduleItem extends Vue {
    isLoading = true;
    isSavingFavorite = false;
    favoriteError = false;
    showingBios = false;
    isSessionActive = false;
    isSessionOver = false;
    sessionLabelInterval = 0;
    timeLabel = "";
    isModalOpen = false;

    isSubmitting = false;
    submitSuccess = false;
    errorMessage = "";
    submitError = false;

    sessionReactive: SessionDataObject | any = {};

    // if the Generic color needs to be change used this state
    // the value will be coming from changeDefaultHeaderColor in the config file
    overideGenericStyle = {
        color: ""
    };

    @Prop({ default: {}, required: true })
    session!: SessionDataObject;

    @Prop(Boolean)
    showDateTime?: boolean;

    @Prop(Boolean)
    isOndemandData?: boolean;

    @Prop({ default: "" })
    accessor!: string;

    /**
     * computed
     */

    get sessionToRender() {
        return this.sessionReactive.sessionId
            ? this.sessionReactive
            : this.session;
    }
    get user() {
        return this.$store.getters.user;
    }

    get userInfo() {
        return this.$store.getters.userInfo;
    }

    get sessionId() {
        const id = this.session.sessionId;
        return id;
    }

    get isVideoSession() {
        return Boolean(!this.session.speakeasyType && !this.session.url);
    }

    get pageConfig() {
        return this.$store.getters.getPageOptions("sessions");
    }

    /**
     * Life cycle
     */
    created() {
        this.checkGenericStyle();
    }

    beforeDestroy() {
        window.clearInterval(this.sessionLabelInterval);
    }

    /**
     * Methods
     */
    fetchData() {
        return sessionStore
            .getSession(this.sessionId)
            .then((res) => {
                return (this.sessionReactive = res);
            })
            .catch((error) => {
                return error;
            });
    }
    checkGenericStyle() {
        this.overideGenericStyle.color = this.pageConfig.changeDefaultHeaderColor;
    }

    showModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleSubmitSessionUpdate() {
        this.isLoading = true;
        eventHub.$emit("session-update-started");
        sessionStore
            .editSession()
            .then(() => {
                eventHub.$emit("session-update-success");
                this.fetchData();
                this.submitSuccess = true;
            })
            .catch(() => {
                eventHub.$emit("session-update-error");
            })
            .finally(() => {
                eventHub.$emit("session-update-done");
                this.isLoading = false;
            });
    }
}
