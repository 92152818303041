


























































































































import { Vue, Component } from "vue-property-decorator";
import svgCalendar from "@/components/svg/svg-calendar.vue";
import svgClock from "@/components/svg/svg-clock.vue";
import { getModule } from "vuex-module-decorators";
import { SessionDataObject } from "@/types/interfaces";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";
import layoutImagesVuexModule from "@/store/vuex-modules/layoutImages";
import AdminSessionScheduleItem from "@/components/admin/sessions/AdminSessionScheduleItem.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import AdSlot from "@/components/shared/AdSlot.vue";
import { format } from "date-fns";

import attendeeScheduleVuexModule from "@/store/vuex-modules/getAttendeeSchedule";
const attendeeScheduleStore = getModule(attendeeScheduleVuexModule);

const layoutImagesStore = getModule(layoutImagesVuexModule);
const sessionStore = getModule(sessionVuexModule);
// use b-modal if modal is needed - see PrerecordedListing.vue

@Component({
    components: {
        svgCalendar,
        Spinners,
        svgClock,
        AdminSessionScheduleItem,
        PageTitleHeader,
        AdSlot
    },
    filters: {
        maxCharacter(value: string) {
            return value.slice(0, 100) + "...";
        }
    }
})
export default class AdminSessionSchedule extends Vue {
    templateAccessor = "";

    isLoading = true;
    isOndemandData = false;
    selected = "";
    topicTitle = "";
    isSessionActive = false;
    sessionLabelInterval = 0;
    timeLabel = "";
    isLoadingFavorites = true;
    totalWidth = 0;
    loadClass = true;
    lessTabsThanTabsContainerWidth = true;

    /**
     * Computed
     */
    get layoutImageSchedule() {
        return layoutImagesStore.getLayoutImage("scheduleHeader");
    }

    get pageConfig() {
        return this.$store.getters.getPageOptions("schedule");
    }

    get pageDecorImage(): string {
        let returnValue = "";
        if (
            this.pageConfig.decorImages &&
            this.pageConfig.decorImages.pageBackground
        ) {
            returnValue = `${process.env.BASE_URL}bg/${this.pageConfig.decorImages.pageBackground}`;
        }
        return returnValue;
    }

    get tabsSchecdule() {
        const dateString = format(window.MgServerTime, "yyyy-MM-dd");
        //serve as tablist
        const tabList = this.sessions.map((tab) => {
            return Vue.prototype.MgFormatISODateTime(
                tab.startTime,
                "yyyy-mm-dd"
            );
        });

        //remove Duplicate date
        const filteredDate = [...new Set(tabList)].sort();

        //active tab
        const dateIndex = filteredDate.findIndex((item: any) => {
            return item === dateString;
        });

        this.selected = dateIndex >= 0 ? tabList[dateIndex] : tabList[0];

        return filteredDate;
    }

    get cssVariables() {
        return this.$store.getters.cssVariables;
    }

    get sessions() {
        /** added this condition, not important but it will get rid of errors
         * and handle sessions smoothly before they are actually created
         **/
        if (
            sessionStore.sessionData.upcomingSessions ||
            sessionStore.sessionData.onDemand
        ) {
            /**
             * If there are no upcoming items we'll use ondemand data
             */
            let returnValue = sessionStore.sessionData.upcomingSessions;

            if (0 === sessionStore.sessionData.upcomingSessions.length) {
                this.isOndemandData = true;
                returnValue = sessionStore.sessionData.onDemand;
            }

            return returnValue;
        }
        /**
         * needs to return as an array as this getters is being used by other function
         **/
        return [];
    }

    //Group session by time slot
    get sessionData() {
        if (this.sessions) {
            return this.sessions
                .filter((data) => {
                    return (
                        Vue.prototype.MgFormatISODateTime(
                            data.startTime,
                            "yyyy-mm-dd"
                        ) === this.selected
                    );
                })
                .reduce((prev: any, nxt: any) => {
                    const key = `${nxt.startTime}-${nxt.endTime}`;

                    prev[key] = [...(prev[key] || []), nxt];
                    return prev;
                }, {});
        }
        return [];
    }

    get sortedSession() {
        const sorted = Object.keys(this.sessionData)
            .sort()
            .reduce((result: any, key: any) => {
                result[key] = this.sessionData[key];
                return result;
            }, {});

        return sorted;
    }

    get detectUserTimeZone() {
        return new Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    /**
     * Lifecycle
     */
    created() {
        const promises = [
            attendeeScheduleStore.getFavoriteSessions(),
            sessionStore.getSessionData({
                tableName: this.$store.getters.awsConfig.sessionTable,
                isDemoData: false
            })
        ];

        Promise.allSettled(promises).then(() => {
            this.isLoading = false;
            this.isLoadingFavorites = false;
        });

        this.templateAccessor = this.$route.meta.title;
    }

    updated() {
        this.totalWidth = 0;
        const elContainer = document.querySelector("#button-tab-container");
        let innerEl = null;

        if (elContainer) {
            innerEl = elContainer.querySelectorAll("button");
        } else {
            return;
        }

        const activeEl = elContainer.querySelector(
            "button.is-active"
        ) as HTMLButtonElement;

        Object(innerEl).forEach(
            (item: HTMLButtonElement) => (this.totalWidth += item.offsetWidth)
        );

        if (elContainer != null && activeEl != null) {
            this.$nextTick(() => {
                const body = document.querySelector("body");
                const scrollArea = elContainer.getBoundingClientRect().width;
                const marginLeft = activeEl.offsetLeft;
                const elWidth = activeEl.offsetWidth;
                let screenSize = window.innerWidth;

                if (body) {
                    screenSize = body.getBoundingClientRect().width;
                }

                if (this.totalWidth > scrollArea) {
                    this.lessTabsThanTabsContainerWidth = false;
                }

                if (marginLeft < scrollArea / 2) {
                    elContainer.scrollLeft = 0;
                } else {
                    if (screenSize <= 1280) {
                        elContainer.scrollLeft =
                            marginLeft - (screenSize / 2 - elWidth / 2);
                    } else if (screenSize < 1980) {
                        elContainer.scrollLeft =
                            marginLeft - screenSize / 2 + elWidth;
                    } else if (screenSize <= 2400) {
                        elContainer.scrollLeft =
                            marginLeft - screen.width / 4 - 52;
                    } else if (screenSize > marginLeft) {
                        elContainer.scrollLeft =
                            marginLeft - screenSize / 4 + elWidth / 2;
                    }
                }
            });
        }
    }

    /**
     * Methods
     */
    parseTimeToISO = (timeStart: string, timeEnd: string) => {
        const newTimeS = Vue.prototype.MgFormatISODateTime(timeStart, "time");
        const newTimeE = Vue.prototype.MgFormatISODateTime(timeEnd, "time");

        return newTimeS + " - " + newTimeE;
    };

    showModal(title: string) {
        this.topicTitle = title;
    }
}
