import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import Store from "../index";
import {
    AttendeeScheduleDay,
    AttendeeScheduleSlot,
    AttendeeDataObject
} from "@/types/interfaces";
import { getApiClient } from "@/services/api";
import { format, parseISO } from "date-fns";

// This module is only for functionality related to the following endpoint:
const endpoint = "schedule";

/**
 * Format schedule
 *
 * @param data object
 * @return Array<AttendeeScheduleDay>
 */
function formatSchedule(data: Array<AttendeeScheduleSlot>) {
    const returnValue: Array<AttendeeScheduleDay> = [];

    let dates: Array<string> = [];

    dates = data.map((item) => {
        const dateFromIso = parseISO(item.startTime || "");
        const formattedDate = format(dateFromIso, "yyyy-MM-dd");

        return formattedDate;
    });

    dates = Array.from(new Set(dates));

    dates.forEach((date: string) => {
        const itemForRetunValue = {
            date: date,
            slots: data.filter((item) => {
                const dateFromIso = parseISO(item.startTime || "");
                const formattedDate = format(dateFromIso, "yyyy-MM-dd");
                const condition1 = date === formattedDate;

                return condition1;
            })
        };

        returnValue.push(itemForRetunValue);
    });

    return returnValue;
}

@Module({
    dynamic: true,
    store: Store,
    name: "getAttendeeSchedule",
    namespaced: true
})
export default class GetAttendeeScheduleModule extends VuexModule {
    scheduleData: Array<AttendeeScheduleDay> = [];
    favoriteSessions: Array<AttendeeScheduleSlot> = [];
    favoritePosters: Array<AttendeeScheduleSlot> = [];
    rsvpsToGet: Array<string> = [];
    scheduleAttendees: Array<AttendeeDataObject> = [];

    @Mutation
    public setRsvpsToGet(data: Array<string>) {
        this.rsvpsToGet = data;
    }

    @Mutation
    public setScheduleAttendees(data: Array<AttendeeDataObject>) {
        this.scheduleAttendees = data;
    }

    @Mutation
    public addScheduleAttendee(data: AttendeeDataObject) {
        if (Object.keys(data).length) {
            this.scheduleAttendees.push(data);
        }
    }

    @Mutation
    public setFavoriteSessions(data: Array<AttendeeScheduleSlot>) {
        this.favoriteSessions = data;
    }

    @Mutation
    public setFavoritePosters(data: Array<AttendeeScheduleSlot>) {
        this.favoritePosters = data;
    }

    @Mutation
    public setAttendeeSchedule(data: Array<AttendeeScheduleDay>) {
        this.scheduleData = data;
    }

    @Action({})
    handleRsvps(data: Array<AttendeeScheduleSlot>) {
        let rsvps: Array<string> = [];

        data.map((item: AttendeeScheduleSlot) => {
            const confirmdedIds = item.networking?.confirmed || [];
            const pendingIds = item.networking?.pending || [];
            const declinedIds = item.networking?.declined || [];
            let hostId: Array<string> = [];

            if (item.networking?.hostId) {
                hostId = [item.networking.hostId];
            }

            rsvps = [
                ...rsvps,
                ...confirmdedIds,
                ...pendingIds,
                ...hostId,
                ...declinedIds
            ];
        });

        rsvps = Array.from(new Set(rsvps));
        this.setRsvpsToGet(rsvps);
    }

    @Action({ commit: "setAttendeeSchedule", rawError: true })
    async getAttendeeSchedule() {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`${endpoint}`, {
                    headers: { Authorization: `bearer ${token}` }
                })
                .then((response) => {
                    const data = response.data;
                    const returnValue = formatSchedule(data);

                    this.setRsvpsToGet([]);
                    this.handleRsvps(data);

                    return resolve(returnValue);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ commit: "setFavoriteSessions", rawError: true })
    async getFavoriteSessions() {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`${endpoint}/sessions`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ commit: "setFavoritePosters", rawError: true })
    async getFavoritePosters() {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`${endpoint}/posters`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }
}
