

























































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";
import { SessionDataObject, SessionSpeakerObject } from "@/types/interfaces";

const sessionStore = getModule(sessionVuexModule);

interface SessionData  {
    sessionId: "";
    title: string;
    description: string;
    speakers: Array<SessionSpeakerObject>;
}

@Component({
    components: {
        PageTitleHeader,
        Spinners
    }
})
export default class EditSession extends Vue {
    @Prop(String)
    id!: string;

    isLoading = true;
    updatePayload: SessionDataObject | SessionData = {
        sessionId: "",
        title: "",
        description: "",
        speakers: []
    };
    resetPayload: SessionDataObject | SessionData = {
        sessionId: "",
        title: "",
        description: "",
        speakers: []
    };
    isSubmitting = false;
    submitSuccess = false;
    errorMessage = "";
    submitError = false;

    addToCategory = "";


    created() {
        this.initializeSession();
    }

    initializeSession() {
        const SessionsPromises = [this.fetchSession()];

        this.isLoading = true;

        Promise.resolve(SessionsPromises).then(() => {
            this.isLoading = false;
        });
    }

    fetchSession() {
        return new Promise<void>((resolve, reject) => {
            sessionStore
                .getSession(this.id)
                .then((response: any) => {
                    this.updatePayload.sessionId= response.sessionId;
                    this.updatePayload.title = response.title;
                    this.updatePayload.description = response.description;
                    this.updatePayload.speakers = response.speakers.length ? [...response.speakers] : [];

                    return resolve();
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    resetForm() {
        this.updatePayload = JSON.parse(JSON.stringify(this.resetPayload));
    }

    setErrorMessage(message: string | undefined) {
        if (message) {
            this.errorMessage = message;
        }
    }

    submitForm() {
        const payload = JSON.parse(JSON.stringify(this.updatePayload));
        this.isSubmitting = true;
        // sessionStore
        //     .editSession(payload)
        //     .then(() => {
        //         this.submitSuccess = true;
        //     })
        //     .catch((error) => {
        //         const data = error?.response?.data;

        //         this.setErrorMessage(data);
        //         this.submitError = true;
        //     })
        //     .finally(() => {
        //         this.isSubmitting = false;
        //     });
    }

    resetSubmitFlags() {
        this.isSubmitting = false;
        this.submitSuccess = false;
        this.submitError = false;
    }

    handleSubmitSuccess() {
        this.resetForm();
        this.$router.push({name:'AdminSession'});
    }
}
