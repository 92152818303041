<template>
    <vue-editor v-model="content" />
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
    name: "wysisyg",
    components: {
        VueEditor
    },
    props: {
        content: {
            type: String,
            default: null
        }
    },
    watch: {
        content: () => {
            this.$emit('changeDescription')
        }
    },
    data: () => ({
    })
};
</script>
<style lang="scss" scoped>
</style>
